import React, { useContext, useState } from 'react';
import { StyledNavLink, StyledWrapper } from './MainNavigation-styling';
import AxeloteLogo from '../../atoms/AxeloteLogo';
import { PageContext } from '../../MainTemplate';
import { RouteType } from '../../../model/Model';

const MainNavigation: React.FC = () => {
  const [mobileMenuActive, setMobileMenuActive] = useState<boolean>(false);
  const { isMobile } = useContext(PageContext);

  const toggleMenu = () => (!isMobile ? setMobileMenuActive(false) : setMobileMenuActive(!mobileMenuActive));

  return (
    <StyledWrapper id="main-navigation">
      <div className="mobile-nav-group">
        <div className={`hamburger-menu ${mobileMenuActive && isMobile ? 'active' : ''}`} onClick={toggleMenu}>
          <div className="ham-bar bar-top"></div>
          <div className="ham-bar bar-mid"></div>
          <div className="ham-bar bar-bottom"></div>
        </div>
        
        {isMobile && <AxeloteLogo />}
        
        <div />
      </div>

      {!isMobile && <AxeloteLogo />}

      <nav className={`${mobileMenuActive && isMobile ? 'active' : ''}`}>
        <StyledNavLink to={`/${RouteType.HOMEPAGE}`} onClick={toggleMenu}>
          <span>Digital Product Passport</span>
        </StyledNavLink>
        <StyledNavLink to={`/${RouteType.ABOUT_US}`} onClick={toggleMenu}>
          <span>About us</span>
        </StyledNavLink>
      </nav>
    </StyledWrapper>
  );
};

export default MainNavigation;
