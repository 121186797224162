import React from 'react';
import styled, { keyframes } from 'styled-components';
import image1 from '../../public/assets/new/baner_about_us.jpg';
import image2 from '../../public/assets/new/korzysci_ze_wspolpracy.jpg';

export const StyledAboutUs = styled.div`
  --font-size-h1: 2em;
  --font-size-h2: 1.6em;
  --font-size-p: 1.2em;
  --font-size-li: 1.1em;

  #team {
    max-width: 100%;
    padding: 40px;
    background-color: #f3f4f6;
    border-radius: 10px;
    color: #333;
    box-sizing: border-box;
    text-align: left;
    margin: 40px 90px;
  }

  #team h1 {
    font-size: var(--font-size-h1);
    color: #0073e6;
    margin-bottom: 20px;
  }

  #team p {
    font-size: var(--font-size-p);
    line-height: 1.6;
    margin-bottom: 20px;
    max-width: 800px;
  }

  #team h2 {
    font-size: var(--font-size-h2);
    color: #333;
    margin: 30px 0 15px;
  }

  #team ul {
    list-style-type: disc;
    padding-left: 40px; /* Indent list for better readability */
    max-width: 800px;
  }

  #team ul li {
    /* font-size: var(--font-size-li); */
    margin-bottom: 15px;
    line-height: 1.5;
    font-size: 1.2rem;
  }

  #team ul li strong {
    color: #0073e6;
  }

  #team p:last-of-type {
    font-size: 1.2em;
    /* font-weight: bold; */
    color: #555;
    margin-top: 20px;
  }

  #team .team-card {
    background: #fff;
    padding: 25px;
    border-radius: 10px;
    margin: 20px 0;

    p {
      max-width: 100%;
      font-size: 1.4rem;
    }
  }

  @media (max-width: 768px) {
    #team {
      margin: 40px 0;
    }

    #team h1 {
      font-size: 1.8rem;
    }

    #team .team-card p {
      font-size: 1.1rem;
    }
  }

  #services {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
    max-width: 100%;
    padding: 40px;
    background-color: #f3f4f6;
    border-radius: 10px;
    color: #333;
    box-sizing: border-box;
    text-align: left;
    margin: 40px 90px;
  }

  #services-container {
  }

  #services h1 {
    font-size: var(--font-size-h1);
    color: #0073e6;
    margin-bottom: 20px;
  }

  #services p {
    line-height: 1.6;
    margin-bottom: 20px;
    max-width: 800px;
  }

  #services h2 {
    font-size: var(--font-size-h2);
    color: #333;
    margin: 30px 0 15px;
  }

  #services ul {
    list-style-type: disc;
    padding-left: 40px; /* Indent list for better readability */
    max-width: 800px;
  }

  #services ul li {
    font-size: var(--font-size-li);
    margin-bottom: 15px;
    line-height: 1.5;
    font-size: 1.4rem;
  }

  #services ul li strong {
    /* color: #0073e6; */
  }

  #services p:last-of-type {
    font-size: 1.2em;
    font-weight: bold;
    color: #555;
    margin-top: 20px;
  }

  @media (max-width: 768px) {
    #services {
      margin: 40px 0;
      flex-direction: column;
    }

    #services h1 {
      font-size: 1.8rem;
    }

    #services ul {
      padding-left: 10px;
    }

    #services ul li {
      font-size: 1.1rem;
    }
  }

  #services2 {
    max-width: 100%;
    padding: 40px;
    background-color: #f3f4f6;
    border-radius: 10px;
    color: #333;
    box-sizing: border-box;
    text-align: left;
    margin: 40px 90px;
  }

  #services2 h1 {
    font-size: var(--font-size-h1);
    color: #0073e6;
    margin-bottom: 20px;
  }

  #services2 p {
    line-height: 1.6;
    margin-bottom: 20px;
    min-width: 100%;
    font-size: 1.2rem;
  }

  #services2 h2 {
    font-size: 1.4rem;
    color: #333;
    margin: 30px 0 15px;
  }

  #services2 ul {
    list-style-type: disc;
    padding-left: 40px; /* Indent list for better readability */
    min-width: 100%;
  }

  #services2 ul li {
    font-size: var(--font-size-li);
    margin-bottom: 15px;
    line-height: 1.5;
    font-size: 1.4rem;
    min-width: 100%;
  }

  #services2 ul li strong {
    /* color: #0073e6; */
  }

  #services2 p:last-of-type {
    font-size: 1.2em;
    font-weight: bold;
    color: #555;
    margin-top: 20px;
  }

  @media (max-width: 768px) {
    #services2 {
      margin: 40px 0;
    }

    #services2 h1 {
      font-size: 1.8rem;
    }

    #services2 ul {
      padding-left: 10px;
    }

    #services2 ul li {
      font-size: 1.1rem;
    }
  }

  .rec1 {
    position: absolute;
    width: 120px;
    height: 10px;
    background: #0049ef;
    top: 0;
    left: 0;
  }

  .rec2 {
    position: absolute;
    width: 10px;
    height: 80px;
    background: #ff0096;
    top: 0;
    left: 0;
    opacity: 0.8;
  }

  .rec3 {
    position: absolute;
    width: 120px;
    height: 10px;
    background: #0049ef;
    bottom: 0;
    right: 0;
  }

  .rec4 {
    position: absolute;
    width: 10px;
    height: 80px;
    background: #ff0096;
    bottom: 0;
    right: 0;
    opacity: 0.8;
  }

  .centeredText {
    text-align: center;
  }

  @media (max-width: 768px) {
    .centeredText {
      font-size: 1.1rem;
    }
  }
`;

export const StyledBanner = styled.div`
  background: linear-gradient(135deg, #005eff, #00d4ff);
  padding: 50px;
  border-radius: 10px;
  text-align: left;
  max-width: 100%;
  max-width: 100%;
  display: flex;
  gap: 50px;
  justify-content: space-between;
  align-items: center;
  margin: 40px 90px;

  @media (max-width: 768px) {
    margin: 40px 0;
    padding: 50px 10px;
    flex-direction: column;
    text-align: center;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 60%;

  @media (max-width: 768px) {
    min-width: 80%;
  }
`;

export const StyledTitle = styled.h1`
  font-size: 2.3rem;
  margin-bottom: 24px;
  color: #fff;
`;

export const StyledImage = styled.div<{ image: string }>`
  width: 50%;
  height: 400px;
  background-image: url(${(props) => props.image});
  background-size: cover;
  border-radius: 10px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const fadeInOut = keyframes`
    0%, 100% {
        opacity: 0;
    }
    10%, 90% {
        opacity: 1;
    }
`;

export const StyledParagraphContainer = styled.div`
  position: relative;
  width: 100%;
  height: 9.8rem;
  overflow: hidden;
  margin-bottom: 80px;

  @media (max-width: 768px) {
    height: 16rem;
    margin-bottom: 40px;
  }
`;

export const StyledParagraph = styled.p`
  font-size: 1.4rem;
  color: #e0e0e0;
  line-height: 1.8;
  position: absolute;
  width: 100%;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

export const StyledButton = styled.button`
  padding: 15px 30px;
  margin-bottom: 20px;
  font-size: 1.2rem;
  color: white;
  background-color: #0041c2;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #002d8a;
  }

  @media (max-width: 768px) {
    align-self: center;
  }
`;

export const AboutUs: React.FC<{ footerRef: any }> = ({ footerRef }) => {
  const scrollToFooter = () => {
    footerRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <StyledAboutUs>
      <StyledBanner>
        <ContentContainer>
          <StyledTitle>Tworzymy przestrzeń dla etycznego biznesu</StyledTitle>
          <StyledParagraphContainer>
            <StyledParagraph>
              Relacje opieramy na wzajemnym szacunku, przejrzystości i zaufaniu. Wierzymy, że trwałe sukcesy buduje się na wartościach, a
              nie na dominacji – naszą ambicją jest być liderem, który inspiruje do uczciwości, odpowiedzialności i empatii w biznesie.
            </StyledParagraph>
          </StyledParagraphContainer>
          <StyledButton onClick={scrollToFooter}>Skontaktuj się</StyledButton>
        </ContentContainer>
        <StyledImage image={image1} />
      </StyledBanner>

      <section id="services">
        <div className="rec1"></div>
        <div className="rec2"></div>
        <div className="rec3"></div>
        <div className="rec4"></div>
        <div id="services-container">
          <h1>Korzyści ze współpracy z nami</h1>
          <ul>
            <li>
              <strong>Skalowalność i elastyczność rozwiązań</strong> – nasze oprogramowanie jest dostosowane do rosnących potrzeb
              biznesowych, umożliwiając łatwe rozwijanie i dostosowywanie do nowych wymagań.
            </li>
            <li>
              <strong>Transparentność i pełna kontrola nad projektem</strong> – dzięki zaangażowaniu Project Managera i Analityka
              Biznesowego oraz wykorzystaniu narzędzi takich jak JIRA i Confluence, klient zyskuje pełną widoczność na każdym etapie
              projektu.
            </li>
            <li>
              <strong>Bezpieczeństwo i zgodność z najnowszymi standardami</strong> – stosujemy zaawansowane technologie oraz procesy DevOps,
              co przekłada się na niezawodność i bezpieczeństwo naszych rozwiązań.
            </li>
          </ul>
        </div>
        <StyledImage image={image2} style={{ backgroundPosition: 'center' }} />
      </section>

      <section id="services2">
        <h1>Sprawdź co możemy Ci zaoferować:</h1>
        <h2>Audyt Wstępny i Analiza Potrzeb</h2>
        <ul>
          <li>Przeprowadzenie szczegółowej analizy w celu oceny aktualnych procesów zarządzania cyklem życia produktów.</li>
          <li>Wyznaczenie priorytetów oraz identyfikacja możliwości integracji DPP w istniejących systemach.</li>
        </ul>
        <h2>Tworzenie Indywidualnych Cyfrowych Paszportów Produktu</h2>
        <ul>
          <li>
            Pełna personalizacja DPP dla każdej kategorii produktowej, zawierająca informacje o pochodzeniu, certyfikatach, śladzie węglowym
            oraz możliwościach recyklingu.
          </li>
          <li>Zapewnienie przejrzystości poprzez implementację technologii kodów QR umożliwiających łatwy dostęp do DPP dla klientów.</li>
        </ul>
        <h2>Integracja z Systemami Sprzedaży i Magazynowania</h2>
        <ul>
          <li>Wdrożenie DPP z istniejącymi systemami ERP, CRM oraz systemami zarządzania łańcuchem dostaw.</li>
          <li>Synchronizacja danych o produktach na wszystkich etapach - od producenta do klienta końcowego.</li>
        </ul>
        <h2>Szkolenie Zespołów i Wsparcie Techniczne</h2>
        <ul>
          <li>Pełne szkolenie pracowników z obsługi i zarządzania DPP.</li>
          <li>Wsparcie techniczne i doradztwo przez cały okres wdrażania i działania systemu.</li>
        </ul>
        <h2>Monitorowanie i Ulepszanie Procesu</h2>
        <ul>
          <li>
            Analiza efektywności wdrożenia DPP, wskaźniki KPI i ocena wyników w kontekście zwiększenia transparentności oraz efektywności
            operacyjnej.
          </li>
          <li>Doradztwo w zakresie optymalizacji oraz rozszerzania możliwości DPP.</li>
        </ul>
        <h2>Specjalnie dla Ciebie</h2>
        <ul>
          <li>
            <strong>Dedykowany zespół specjalistów:</strong> Każde wdrożenie jest prowadzone przez ekspertów w dziedzinie zarządzania cyklem
            życia produktów, którzy mają doświadczenie w pracy z dużymi sieciami handlowymi.
          </li>
          <li>
            <strong>Najwyższe standardy bezpieczeństwa:</strong> Dane przechowywane w DPP są w pełni bezpieczne, co pozwala chronić poufność
            oraz własność intelektualną.
          </li>
          <li>
            <strong>Skalowalność rozwiązania:</strong> Nasz system DPP można łatwo dostosować do rosnących potrzeb i rozszerzeń asortymentu.
          </li>
        </ul>
        <p className="centeredText" style={{ color: '#0073e6', fontSize: '1.6rem', marginTop: '40px' }}>
          Indywidualne Rozwiązania DPP pisane na miarę
        </p>
      </section>

      <section id="team">
        <h1>O Naszym Zespole</h1>

        <div className="team-card">
          <h2>Project Manager</h2>
          <p>
            Specjalizuje się w zarządzaniu złożonymi projektami technologicznymi, koordynując pracę zespołów programistycznych oraz
            współpracując z kluczowymi interesariuszami. Ponad dwudziestoletnie doświadczenie w zarządzaniu projektami dla sektora
            publicznego oraz komercyjnego.
          </p>
        </div>

        <div className="team-card">
          <h2>Analityk Biznesowy</h2>
          <p>
            Nasz Analityk Biznesowy może pochwalić się bogatym doświadczeniem w pracy dla sektora pozarządowego, samorządowego, prywatnego
            oraz IT, specjalizuje się w analizie potrzeb biznesowych, projektowaniu rozwiązań oraz zarządzaniu wymaganiami w złożonych
            środowiskach projektowych.
          </p>
        </div>

        <div className="team-card">
          <h2>Senior Developer</h2>
          <p>
            Wieloletnie szerokie doświadczenie w projektowaniu i wdrażaniu aplikacji webowych oraz systemów backendowych przy użyciu
            najnowszych technologii obejmujący zarówno nowoczesne technologie frontendowe, takie jak React, Angular i TypeScript, jak i
            technologie backendowe, w tym Java, Spring Boot i Hibernate. Z sukcesem pracował nad projektami dla sektora publicznego oraz
            komercyjnego, realizując złożone systemy e-commerce, zarządzania treścią oraz aplikacje oparte na mikroserwisach.
          </p>
        </div>

        <div className="team-card">
          <h2>Senior Developer</h2>
          <p>
            Doświadczenie w tworzeniu złożonych aplikacji backendowych z wykorzystaniem technologii Java oraz architektury mikroserwisów.
            Pracował z projektami które wymagają integracji z systemami zewnętrznymi oraz zapewnienia stabilności i skalowalności rozwiązań
            w środowiskach korporacyjnych i startupowych.
          </p>
        </div>

        <div className="team-card">
          <h2>Regular Developer</h2>
          <p>
            Nastawiony na rozwój aplikacji internetowych z wykorzystaniem najnowszych technologii frontendowych, takich jak React, Vue oraz
            narzędzi typu Redux i Vuex. Szerokie umiejętności w pracy z architekturą mikroserwisów, a także zintegrowanym środowiskiem
            backendowym, jak Java Spring i PostgreSQL.
          </p>
        </div>

        <div className="team-card">
          <h2>Regular Developer</h2>
          <p>
            Wyspecjalizowany w technologiach Java oraz architekturze mikroserwisów. Realizował projekty o wysokich wymaganiach, zarówno dla
            sektora publicznego, jak i komercyjnego, zapewniając stabilność i wydajność wdrażanych rozwiązań.
          </p>
        </div>
      </section>
    </StyledAboutUs>
  );
};
