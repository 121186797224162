import React, { useState } from 'react';
import {
  StyledTable,
  StyledTitle,
  StyledTableRow,
  StyledTableHeader,
  StyledTableData,
  StyledButton,
  StyledContainer,
  StyledSubRow,
} from './DemoDPPProductPage-styling';

interface Subcategory {
  id: string;
  field: string;
  value: string;
}

interface DataRow {
  id: string;
  category: string;
  details: string;
  subcategories?: Subcategory[];
}

const data: DataRow[] = [
  { id: '1', category: 'Nazwa produktu', details: 'Ekologiczna torba na zakupy' },
  {
    id: '2',
    category: 'Producent',
    details: 'GreenCo',
    subcategories: [
      { id: '2-1', field: 'Adres siedziby', value: 'Warszawa, Polska' },
      { id: '2-2', field: 'Numer telefonu', value: '+48 123 456 789' },
      { id: '2-3', field: 'Strona internetowa', value: 'www.zakladx.pl' },
      { id: '2-4', field: 'Certyfikaty i normy', value: 'ISO 9001' },
    ],
  },
  { id: '3', category: 'Certyfikat produkcji', details: 'XYZ' },
  {
    id: '4',
    category: 'Skład produktu',
    details: '100% bawełna organiczna',
    subcategories: [
      { id: '4-1', field: 'Materiał bazowy', value: 'Bawełna organiczna' },
      { id: '4-2', field: 'Pochodzenie materiałów', value: 'Polska' },
      { id: '4-3', field: 'Szczegóły chemicznych dodatków', value: 'Brak' },
    ],
  },
  { id: '5', category: 'Pochodzenie materiałów', details: 'Polska' },
  { id: '6', category: 'Długowieczność', details: '10 lat' },
  {
    id: '7',
    category: 'Zalecenia dotyczące użytkowania',
    details: 'Prać w temperaturze do 30°C',
    subcategories: [
      { id: '7-1', field: 'Data produkcji', value: '01.01.2023' },
      { id: '7-2', field: 'Szczegółowe zalecenia', value: 'Unikaj prania chemicznego' },
      { id: '7-3', field: 'Instrukcje do pobrania', value: 'www.ins.pl' },
      { id: '7-4', field: 'Strona internetowa produktu', value: 'www.produkt.pl' },
    ],
  },
  {
    id: '8',
    category: 'Całkowity ślad węglowy produktu',
    details: '5 kg CO₂',
    subcategories: [
      { id: '8-1', field: 'Emisja CO2', value: '25 kg CO2' },
      { id: '8-2', field: 'Ślad węglowy transportu', value: '5 kg CO2' },
    ],
  },
  { id: '9', category: 'Odległości pokonane', details: '1700 km' },
  {
    id: '10',
    category: 'Możliwość recyklingu',
    details: 'Tak, w 100%',
    subcategories: [
      { id: '10-1', field: 'Szczegółowe możliwości recyklingu', value: 'Plastik, Papier' },
      { id: '10-2', field: 'Znajdź najbliższy punkt PSZOK', value: 'www.pszok.pl' },
      { id: '10-3', field: 'Zalecenia dotyczące utylizacji', value: 'Zanieś do punktu PSZOK' },
      { id: '10-4', field: 'Ponowne użycie', value: 'Tak' },
    ],
  },
];

export const DemoDPPProductPage: React.FC = () => {
  const [expandedRows, setExpandedRows] = useState<Set<string>>(new Set());

  const toggleRow = (id: string) => {
    setExpandedRows((prev) => {
      const newExpandedRows = new Set(prev);
      if (newExpandedRows.has(id)) {
        newExpandedRows.delete(id);
      } else {
        newExpandedRows.add(id);
      }
      return newExpandedRows;
    });
  };

  return (
    <StyledContainer>
      <StyledTitle>Informacje o produkcie</StyledTitle>
      <StyledTable>
        <thead>
          <StyledTableRow>
            <StyledTableHeader>Kategoria</StyledTableHeader>
            <StyledTableHeader>Szczegóły</StyledTableHeader>
          </StyledTableRow>
        </thead>
        <tbody>
          {data.map((item) => (
            <React.Fragment key={item.id}>
              <StyledTableRow onClick={() => toggleRow(item.id)}>
                <StyledTableData>{item.category}</StyledTableData>
                <StyledTableData>{item.details}</StyledTableData>
              </StyledTableRow>
              {expandedRows.has(item.id) &&
                item.subcategories &&
                item.subcategories.map((sub) => (
                  <StyledSubRow key={sub.id}>
                    <StyledTableData>{sub.field}</StyledTableData>
                    <StyledTableData>{sub.value}</StyledTableData>
                  </StyledSubRow>
                ))}
            </React.Fragment>
          ))}
        </tbody>
      </StyledTable>
      <StyledButton>Rozwiń szczegóły</StyledButton>
    </StyledContainer>
  );
};

export default DemoDPPProductPage;
