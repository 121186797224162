import styled from 'styled-components';

export const StyledFooterWrapper = styled.footer`
  display: flex;
  justify-content: space-around;
    align-items: flex-start;
  padding: 50px 40px;
  background-color: #2a2a2a;
  color: white;
  width: 100%;

  @media (max-width: 768px) {
   min-width: 120px;
    flex-direction: column;
  }
`;

export const StyledCompanyName = styled.h2`
  font-size: 1.6rem;
  margin-bottom: 20px;
  color: #fff;
`;

export const StyledText = styled.p`
  font-size: 1rem;
  color: #777;
  line-height: 1.5;
  margin-bottom: 80px;
  max-width: 500px;
`;

export const StyledLinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 120px;
  gap: 32px;
`;

export const StyledLink = styled.a`
  color: #fff;
  text-decoration: none;
  font-size: 1.1rem;
  transition: color 0.3s ease;

  &:hover {
    color: #00bfff;
  }
`;
