import React from 'react';

const PrivacyPolicy: React.FC = () => {

    return (
        <>
            <h1>Polityka Prywatności</h1>

            <p><strong>SmartSource Dawid Senko</strong><br/>
                NIP: 8581854563<br/>
                Adres: ul. Elżbiety Zawackiej 3, 66-400 Gorzów Wielkopolski</p>

            <h2>Wprowadzenie</h2>
            <p>Ochrona prywatności i bezpieczeństwa danych jest dla nas priorytetem. W niniejszej Polityce Prywatności
                informujemy o zasadach przetwarzania danych osobowych, w tym o zbieraniu plików cookie na naszej stronie
                internetowej.</p>

            <h2>1. Administrator Danych Osobowych</h2>
            <p>Administratorem danych osobowych jest SmartSource Dawid Senko, ul. Elżbiety Zawackiej 3, 66-400 Gorzów
                Wielkopolski, NIP: 8581854563.</p>

            <h2>2. Zakres Przetwarzanych Danych</h2>
            <p>Podczas korzystania z naszej strony internetowej możemy zbierać:</p>
            <ul>
                <li>Dane techniczne dotyczące urządzenia, z którego korzysta użytkownik (np. IP, przeglądarka, system
                    operacyjny),
                </li>
                <li>Informacje o sposobie korzystania z naszej strony, uzyskane za pomocą plików cookie.</li>
            </ul>

            <h2>3. Wykorzystywanie Plików Cookie</h2>
            <p>Na naszej stronie używamy plików cookie w celach analitycznych, aby:</p>
            <ul>
                <li>Zrozumieć, jak użytkownicy korzystają z naszej strony,</li>
                <li>Doskonalić funkcjonalność i treści strony,</li>
                <li>Analizować ruch na stronie oraz optymalizować nasze działania marketingowe.</li>
            </ul>

            <h3>Rodzaje plików cookie, które możemy zbierać:</h3>
            <ul>
                <li><strong>Analityczne</strong>: umożliwiają nam mierzenie aktywności użytkowników na stronie i
                    dostosowanie jej do ich preferencji.
                </li>
            </ul>

            <h2>4. Cele Przetwarzania Danych</h2>
            <p>Dane zbierane za pomocą plików cookie są przetwarzane wyłącznie w celach analitycznych i statystycznych,
                aby poprawić komfort użytkowników i usprawnić działanie strony internetowej.</p>

            <h2>5. Podstawa Prawna Przetwarzania Danych</h2>
            <p>Przetwarzanie danych osobowych odbywa się zgodnie z RODO (Rozporządzenie Parlamentu Europejskiego i Rady
                (UE) 2016/679 z dnia 27 kwietnia 2016 r.) na podstawie:</p>
            <ul>
                <li>Zgody użytkownika na przetwarzanie danych w celach analitycznych.</li>
            </ul>

            <h2>6. Przekazywanie Danych Osobowych</h2>
            <p>Zebrane dane nie będą przekazywane innym podmiotom bez zgody użytkownika, chyba że jest to wymagane
                przepisami prawa.</p>

            <h2>7. Prawa Użytkowników</h2>
            <p>Zgodnie z RODO, użytkownik ma prawo do:</p>
            <ul>
                <li>Dostępu do swoich danych osobowych,</li>
                <li>Sprostowania lub usunięcia danych,</li>
                <li>Ograniczenia przetwarzania danych,</li>
                <li>Wycofania zgody na przetwarzanie danych w dowolnym momencie,</li>
                <li>Przenoszenia danych,</li>
                <li>Wniesienia skargi do organu nadzorczego.</li>
            </ul>

            <h2>8. Zarządzanie Plikami Cookie</h2>
            <p>Użytkownik może w każdej chwili zmienić ustawienia przeglądarki, aby zablokować pliki cookie lub
                otrzymywać
                powiadomienia, gdy są one wysyłane. Wyłączenie plików cookie może jednak wpłynąć na funkcjonalność
                strony.</p>

            <h2>9. Zmiany w Polityce Prywatności</h2>
            <p>Zastrzegamy sobie prawo do wprowadzania zmian w Polityce Prywatności, które będą publikowane na tej
                stronie. Zalecamy regularne sprawdzanie niniejszej Polityki, aby być na bieżąco z ewentualnymi
                modyfikacjami.</p>

            <h2>10. Kontakt</h2>
            <p>W przypadku pytań dotyczących niniejszej Polityki Prywatności lub przetwarzania danych osobowych, prosimy
                o
                kontakt:</p>
            <p><strong>SmartSource Dawid Senko</strong><br/>
                Adres: ul. Elżbiety Zawackiej 3, 66-400 Gorzów Wielkopolski<br/>
                Email: [Twój adres e-mail]</p>

            <p>Niniejsza Polityka Prywatności jest ważna od daty publikacji i obowiązuje na stronie internetowej
                prowadzonej przez SmartSource Dawid Senko.</p>

        </>
    );
};

export default PrivacyPolicy;
