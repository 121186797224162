import React, { useState, useEffect } from 'react';
import {
  StyledTable,
  StyledTitle,
  StyledTableRow,
  StyledTableHeader,
  StyledTableData,
  StyledButton,
  StyledContainer,
  StyledSubRow,
} from './DemoDPPProductPage2-styling';

export const DemoDPPProductPage2: React.FC<{ productId: string }> = ({ productId }) => {
  const BASE_URL = 'http://localhost:3004';
  const [productData, setProductData] = useState<any>(null);
  const [expandedRows, setExpandedRows] = useState<Set<string>>(new Set());

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const res = await fetch(`${BASE_URL}/dpp-demo-get-product-data/${productId}`);
        if (!res.ok) throw new Error('Błąd podczas pobierania danych');

        const data = await res.json();
        setProductData(data);
      } catch (error) {
        console.error('Błąd podczas pobierania danych:', error);
      }
    };

    fetchProductData();
  }, []);
  console.log(productData);

  const toggleRow = (id: string) => {
    setExpandedRows((prev) => {
      const newExpandedRows = new Set(prev);
      if (newExpandedRows.has(id)) {
        newExpandedRows.delete(id);
      } else {
        newExpandedRows.add(id);
      }
      return newExpandedRows;
    });
  };

  if (!productData) {
    return <div>Ładowanie danych...</div>;
  }

  return (
    <StyledContainer>
      <StyledTitle>Informacje o produkcie</StyledTitle>
      <StyledTable>
        <thead>
          <StyledTableRow>
            <StyledTableHeader>Kategoria</StyledTableHeader>
            <StyledTableHeader>Szczegóły</StyledTableHeader>
          </StyledTableRow>
        </thead>
        <tbody>
          <React.Fragment>
            <StyledTableRow onClick={() => toggleRow('productName')}>
              <StyledTableData>Nazwa produktu</StyledTableData>
              <StyledTableData>{productData.productName || productData.name}</StyledTableData>
            </StyledTableRow>
            <StyledTableRow onClick={() => toggleRow('manufacturer')}>
              <StyledTableData>Producent</StyledTableData>
              <StyledTableData>{productData.producerData.companyName}</StyledTableData>
            </StyledTableRow>
            {expandedRows.has('manufacturer') && (
              <>
                <StyledSubRow>
                  <StyledTableData>Adres siedziby</StyledTableData>
                  <StyledTableData>{productData.producerData.address}</StyledTableData>
                </StyledSubRow>
                <StyledSubRow>
                  <StyledTableData>Numer telefonu</StyledTableData>
                  <StyledTableData>{productData.producerData.contactPhone}</StyledTableData>
                </StyledSubRow>
                <StyledSubRow>
                  <StyledTableData>Kraj</StyledTableData>
                  <StyledTableData>{productData.producerData.country}</StyledTableData>
                </StyledSubRow>
              </>
            )}
            <StyledTableRow onClick={() => toggleRow('productionCertificate')}>
              <StyledTableData>Certyfikat produkcji</StyledTableData>
              <StyledTableData>{productData.qualityCertificates}</StyledTableData>
            </StyledTableRow>
            <StyledTableRow onClick={() => toggleRow('productComposition')}>
              <StyledTableData>Skład produktu</StyledTableData>
              <StyledTableData>{productData.materialComposition}</StyledTableData>
            </StyledTableRow>
            <StyledTableRow onClick={() => toggleRow('materialOrigin')}>
              <StyledTableData>Pochodzenie materiałów</StyledTableData>
              <StyledTableData>{productData.productLifeCycle.placeOfOrigin}</StyledTableData>
            </StyledTableRow>
            <StyledTableRow onClick={() => toggleRow('longevity')}>
              <StyledTableData>Długowieczność</StyledTableData>
              <StyledTableData>{productData.productLifeCycle.productDurability}</StyledTableData>
            </StyledTableRow>
            <StyledTableRow onClick={() => toggleRow('usageRecommendations')}>
              <StyledTableData>Zalecenia dotyczące użytkowania</StyledTableData>
              <StyledTableData>{productData.productLifeCycle.usageRecommendations}</StyledTableData>
            </StyledTableRow>
            <StyledTableRow onClick={() => toggleRow('totalCarbonFootprint')}>
              <StyledTableData>Całkowity ślad węglowy produktu</StyledTableData>
              <StyledTableData>{productData.productLifeCycle.carbonFootprint}</StyledTableData>
            </StyledTableRow>
            <StyledTableRow onClick={() => toggleRow('distancesTravelled')}>
              <StyledTableData>Odległości pokonane</StyledTableData>
              <StyledTableData>{productData.distancesTravelled}</StyledTableData>
            </StyledTableRow>
            <StyledTableRow onClick={() => toggleRow('recyclability')}>
              <StyledTableData>Możliwość recyklingu</StyledTableData>
              <StyledTableData>{productData.recyclability ? 'Tak' : 'Nie'}</StyledTableData>
            </StyledTableRow>
          </React.Fragment>
        </tbody>
      </StyledTable>
      <StyledButton>Rozwiń szczegóły</StyledButton>
    </StyledContainer>
  );
};
