import React from 'react';
import Header from '../../atoms/Header';
import Button from '../../atoms/Button';
import { StyledContainer } from './ContactUsForm-styling';
import axios from 'axios';
import P from '../../atoms/Paragraph';
import styled from 'styled-components';

const initialValues = {
  messageContent: '',
  contactPerson: '',
  companyName: '',
  phoneNumber: '',
  country: '',
  email: '',
};

interface Props {
  messageContent?: string;
  headerText?: string;
}

const StyledRow = styled.div`
  display: flex;
  margin-bottom: 6px;

  > input:first-child {
    margin-right: 6px;
  }

  > textarea {
    width: 100%;
  }

  @media (max-width: 768px) {
    width: 80vw;
  }
`;

const ContactUsForm: React.FC<Props> = ({ messageContent = '', headerText = 'Skontaktuj się z nami' }) => {
  const [formState, setFormState] = React.useState(initialValues);
  const [formSended, setFormSended] = React.useState<boolean>(false);

  const submitForm = async (evt: React.FormEvent<HTMLInputElement>) => {
    evt.preventDefault();
    const formData = formState;

    if (formState.companyName === '' && formState.contactPerson === '') {
      window.alert('Company name or name of the contact person must be provided');
      return;
    }

    if (formState.email === '') {
      window.alert('Email must be provided');
      return;
    }

    if (messageContent === '') {
      formData.messageContent = '';
    } else {
      formData.messageContent = messageContent;
    }

    axios
      .post('https://enterprise.axelote.com/contact', formData)
      .then((response) => {
        setFormSended(true);
        console.log('response', response);
      })
      .catch(function (error) {
        console.log(error);
        setFormSended(true);
      });

    setFormState(initialValues);
  };

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = evt.target.value;
    setFormState({
      ...formState,
      [evt.target.name]: value,
    });
  };

  return (
    <StyledContainer className="contact-us-container">
      <Header size="l" text={headerText} />
      {!formSended ? (
        <>
          <form>
            <StyledRow>
              <input
                type="text"
                placeholder="Imię i nazwisko *"
                name="contactPerson"
                value={formState.contactPerson}
                onChange={handleChange}
              />
              <input type="email" placeholder="E-mail *" name="email" value={formState.email} onChange={handleChange} />
            </StyledRow>
            <StyledRow>
              <input type="text" placeholder="Nazwa firmy" name="companyName" value={formState.companyName} onChange={handleChange} />
              <input
                type="text"
                placeholder="Telefon kontaktowy"
                name="phoneNumber"
                value={formState.phoneNumber}
                onChange={handleChange}
              />
            </StyledRow>
            <StyledRow>
              <textarea
                placeholder={messageContent === '' ? '' : messageContent}
                name="messageContent"
                value={formState.messageContent}
                onChange={handleChange}
              />
            </StyledRow>
          </form>
          <Button type="submit" asButton onClick={submitForm} text="Wyślij" bordercolor={'blue'} backgroundColor="blue" />
        </>
      ) : (
        <P className="send-success" text={'Dziękujemy, skontaktujemy się z Tobą tak szybko jak to możliwe'} size={'xs'} />
      )}
    </StyledContainer>
  );
};

export default ContactUsForm;
