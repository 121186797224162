import styled from 'styled-components';

export const StyledContainer = styled.div`
  width: 100%;
  margin: 10px auto;
  padding: 20px;
  border-radius: 8px;
  overflow-y: scroll;
`;

export const StyledTitle = styled.table`
  margin-top: 80px;
  margin-bottom: 20px;
  font-size: 1.8rem;
  font-weight: bold;
`;

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 40px;
`;

export const StyledTableRow = styled.tr`
  cursor: pointer;

  &:nth-child(even) {
    background-color: #ddd;
  }
`;

export const StyledTableHeader = styled.th`
  padding: 12px;
  color: #333;
  font-size: 1.3rem;
  text-align: left;
  font-weight: bold;
  border-bottom: 2px solid #005a8c;
`;

export const StyledTableData = styled.td`
  padding: 18px 10px;
  text-align: left;
  color: #333;
  border-bottom: 1px solid #ddd;
  font-size: 1rem;
`;

export const StyledSubRow = styled.tr`
  background-color: #f0f8ff;
  padding-left: 5px;

  ${StyledTableData} {
    padding-left: 10px;
    color: #555;
  }
  `;

export const StyledSubTableData = styled.td`
padding: 12px;
text-align: left;
color: #333;
border-bottom: 1px solid #ddd;
font-size: 0.9rem;
`;

export const StyledButton = styled.button`
  padding: 10px 20px;
  background-color: #007acc;
  color: white;
  font-size: 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  
  &:hover {
    background-color: #005a8c;
  }
  `;

export const StyledBackButton = styled.button`
    position: absolute;
    top: 10px;
    left: 10px;
    padding: 10px 15px;
    font-size: 1rem;
    font-weight: bold;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  
    &:hover {
      background-color: #0056b3;
    }
  `;




// import styled from 'styled-components';

// export const StyledContainer = styled.div`
//   margin: 20px 15px;
//   padding: 10px;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   position: relative;
// `;

// export const StyledTitle = styled.h1`
//   font-size: 1.8rem;
//   font-weight: bold;
//   margin: 80px 0;
//   text-align: center;
// `;

// export const StyledDataItem = styled.div`
//   width: 80%;
//   padding: 15px;
//   margin: 10px 0;
//   font-size: 1.2rem;
//   font-weight: bold;
//   color: #333;
//   background-color: #b5b5b5;
//   border-radius: 5px;
//   text-align: center;
// `;

