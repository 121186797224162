import styled from 'styled-components';

export const StyledContainer = styled.div`
  width: 100%;
  margin: 10px auto;
  padding: 20px;
  border-radius: 8px;
  overflow-y: scroll;
`;

export const StyledTitle = styled.table`
  margin-bottom: 20px;
  font-size: 1.5rem;
  font-weight: bold;
`;

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 40px;
`;

export const StyledTableRow = styled.tr`
  cursor: pointer;

  &:nth-child(even) {
    background-color: #ddd;
  }
`;

export const StyledTableHeader = styled.th`
  padding: 12px;
  color: #333;
  font-size: 1.2rem;
  text-align: left;
  font-weight: bold;
  border-bottom: 2px solid #005a8c;
`;

export const StyledTableData = styled.td`
  padding: 10px;
  text-align: left;
  color: #333;
  border-bottom: 1px solid #ddd;
`;

export const StyledSubRow = styled.tr`
  background-color: #f0f8ff;
  font-size: 1rem;
  padding-left: 5px;

  ${StyledTableData} {
    padding-left: 10px;
    color: #555;
  }
`;

export const StyledButton = styled.button`
  padding: 10px 20px;
  background-color: #007acc;
  color: white;
  font-size: 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #005a8c;
  }
`;
