import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import MainTemplate from '../components/MainTemplate';
import MainNavigation from '../components/molecules/mainNavigation/MainNavigation';
import { RouteType } from '../model/Model';
import React, { useRef, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Footer from '../components/molecules/footer/Footer';
import { ArrowNav } from '../components/molecules/arrowNav/ArrowNav';
import { DemoScan } from './DemoScan';
import PrivacyPolicy from './PrivacyPolicy';
import { Homepage } from './Homepage';
import { AboutUs } from './AboutUs';
import 'typeface-montserrat';
import { DemoDPPProductPage2 } from '../components/molecules/demoDPPProductPage2/DemoDPPProductPage2';
import { DemoDPPMainView } from '../components/molecules/demoDPPMainView/DemoDPPMainView';
import { DemoDPPInfoView } from '../components/molecules/demoDPPInfoView/DemoDPPInfoView';
import { DemoDPPMapView } from '../components/molecules/demoDPPMapView/DemoDPPMapView';

interface Props {
  content?: JSX.Element;
  route: RouteType;
}

// const BASE_URL = 'https://cr-api-dev.axelote.com';
// const [QRCode, setQRCode] = useState();

// useEffect(() => {
//   const fetchQRData = async () => {
//     try {
//       console.log(QRCode);
//       const res = await fetch(`${BASE_URL}/dpp-demo-get-qr-code-for-product`);
//       if (!res.ok) throw new Error('Błąd podczas pobierania danych');

//       const data = await res.json();
//       setQRCode(data);
//     } catch (error) {
//       console.error('Błąd podczas pobierania danych:', error);
//     }
//   };

//   fetchQRData();
// }, []);

const PageSelector: React.FC<Props> = ({ route }) => {
  const upperRef = useRef<HTMLDivElement>(null);
  const footerRef = useRef<HTMLDivElement>(null);
  const isDemoScan = location.href.indexOf(RouteType.DEMO_SCAN) > -1;
  const productId = '67375ab4d1a167f408d006b3';

  return (
    <>
      <div ref={upperRef}></div>
      {!isDemoScan && <MainNavigation />}
      <main>
        {
          {
            [RouteType.HOMEPAGE]: <Homepage footerRef={footerRef} />,
          }[route]
        }
      </main>
      <main>
        {
          {
            [RouteType.ABOUT_US]: <AboutUs footerRef={footerRef} />,
          }[route]
        }
      </main>
      <main>
        {
          {
            [RouteType.PRIVACY_POLICY]: <PrivacyPolicy />,
          }[route]
        }
      </main>
      <main>
        {
          {
            [RouteType.DEMO_SCAN]: <DemoScan />,
          }[route]
        }
      </main>
      <main>
        {
          {
            [RouteType.DEMO_VIEW_MAIN]: <DemoDPPMainView productId={productId} />,
          }[route]
        }
      </main>
      <main>
        {
          {
            [RouteType.DEMO_VIEW_INFO]: <DemoDPPInfoView productId={productId} />,
          }[route]
        }
      </main>
      <main>
        {
          {
            [RouteType.DEMO_VIEW_MAP]: <DemoDPPMapView productId={productId} />,
          }[route]
        }
      </main>
      <main>
        {
          {
            [RouteType.DEMO_SCAN2]: <DemoDPPProductPage2 productId={productId} />,
          }[route]
        }
      </main>

      {!isDemoScan && (
        <>
          {' '}
          <ArrowNav upperRef={upperRef} />{' '}
          <div ref={footerRef}>
            <Footer />
          </div>
        </>
      )}
    </>
  );
};

const App: React.FC = () => {
  return (
    <MainTemplate>
      <Helmet>
        <title>Axelote</title>
      </Helmet>
      <BrowserRouter>
        <Routes>
          {(Object.values(RouteType) as RouteType[]).map((route) => {
            return <Route key={route} path={'/' + route} element={<PageSelector route={route} />} />;
          })}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    </MainTemplate>
  );
};

export default App;
