import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
    @import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500&display=swap');

    *, *::before, *::after {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
    }

    html, body {
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
        overflow-x: hidden;
    }

    html {
        scroll-behavior: smooth;
        overflow-x: hidden;
    }

    body {
        font-family: 'Work Sans', sans-serif;
        letter-spacing: -0.25px;
        font-size: 14px;
        line-height: 1.125;
        overflow-x: hidden;
        background: #fff;

        @media (max-width: 1100px) {
            * section > img, * ::after, * ::before, svg {
                transform: scale(0.5) !important;
                background-size: 50% !important;
            }
        }
    }

    main {
        margin: 0 auto;
        max-width: 1430px;
    }

    section {
        position: relative;
    }

    main {
        z-index: 1;
        position: relative;
    }
`;

export default GlobalStyle;
