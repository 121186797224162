export const theme = {
  size: {
    xs: '13px',
    s: '19px',
    m: '26px',
    l: '33px',
    xl: '52px',
    xxl: '72px',
    mobile_xs: '13px', // mobile
    mobile_s: '16px',
    mobile_m: '21px',
    mobile_l: '21px',
    mobile_xl: '27px',
    mobile_xxl: '29px',
  },
  line_height: {
    xs: '19px',
    s: '27px',
    m: '33px',
    l: '42px',
    xl: '64px',
    xxl: '88px',
    mobile_xs: '19px', // mobile
    mobile_s: '22px',
    mobile_m: '29px',
    mobile_l: '29px',
    mobile_xl: '35px',
    mobile_xxl: '38px',
  },
  color: {
    blue: '#0049ef',
    mediumBlue: '#00267e',
    darkBlue: '#000035',
    darkGray: '#2a2a54',
    gray: '#eff2f8',
    lightGray: '#f0f0f0',
    black: '#1a1a1a',
    pink: '#ff0e9c',
    white: '#ffffff',
    turquoise: '#00dfa3',
    almostBlack: '#373a3a',
  },
};