import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { StyledContainer, StyledTitle, StyledMapPlaceholder, StyledLabel, StyledBackButton } from './DemoDPPMapView-styling';

export const DemoDPPMapView: React.FC<{ productId: string }> = ({ productId }) => {
  const navigate = useNavigate();
  const BASE_URL = 'https://cr-api-dev.axelote.com';
  const [productData, setProductData] = useState<any>(null);

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const res = await fetch(`${BASE_URL}/dpp-demo-get-product-data/${productId}`);
        if (!res.ok) throw new Error('Błąd podczas pobierania danych');

        const data = await res.json();
        setProductData(data);
      } catch (error) {
        console.error('Błąd podczas pobierania danych:', error);
      }
    };

    fetchProductData();
  }, [productId]);
  console.log(productData);

  const handleBackClick = () => {
    navigate('/dpp-demo-app-main');
  };

  if (!productData) {
    return <div>Ładowanie danych...</div>;
  }

  return (
    <StyledContainer>
      <StyledBackButton onClick={handleBackClick}>Wróć</StyledBackButton>
      <StyledTitle>{productData.productName}</StyledTitle>
      <StyledMapPlaceholder />
      <StyledLabel>
        <strong>Producent:</strong> {productData.producerData.companyName}
      </StyledLabel>
      <StyledLabel>
        <strong>Dostawca:</strong> {productData.distributor}
      </StyledLabel>
      <StyledLabel>
        <strong>Km przebyte:</strong>{' '}
      </StyledLabel>
      <StyledLabel>
        <strong>Ślad węglowy:</strong> {productData.productLifeCycle.carbonFootprint}
      </StyledLabel>
    </StyledContainer>
  );
};
