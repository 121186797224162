import styled from 'styled-components';

export const StyledContainer = styled.div`
  width: 100%;
  margin: 20px 0px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

export const StyledTitle = styled.h1`
  font-size: 1.8rem;
  font-weight: bold;
  margin-top: 80px;
  margin-bottom: 20px;
  text-align: center;
`;

export const StyledMapPlaceholder = styled.div`
  width: 90%;
  height: 200px;
  background-color: #d3d3d3;
  margin-bottom: 20px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  &::after {
    content: '📍';
    font-size: 2rem;
  }
`;

export const StyledLabel = styled.div`
  width: 90%;
  padding: 10px;
  margin: 5px 0;
  font-size: 1.1rem;
  color: #333;
  background-color: #f0f0f0;
  border-radius: 5px;
  text-align: center;
`;

export const StyledBackButton = styled.button`
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 10px 15px;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;
