import React, {createContext, useEffect, useState} from 'react';
import GlobalStyle from '../styles/GlobalStyle';
import {ThemeProvider} from 'styled-components';
import {theme} from '../styles/Theme';
import {ContextType} from '../model/Model';

interface Props {
    children: React.ReactNode;
}

export const PageContext = createContext<ContextType>({} as ContextType);

const MainTemplate: React.FC<Props> = ({children}): JSX.Element => {
    const [isMobile, setIsMobile] = useState<boolean>(false);

    const handleMobile = () => setIsMobile(window.innerWidth < 1100 || screen.width < 1100);

    useEffect(() => {
        handleMobile();
        window.addEventListener('resize', handleMobile);
        return () => window.removeEventListener('resize', handleMobile);
    });

    return (
        <PageContext.Provider value={{isMobile}}>
            <ThemeProvider theme={theme}>
                <GlobalStyle/>
                {children}
            </ThemeProvider>
        </PageContext.Provider>
    );
};

export default MainTemplate;
