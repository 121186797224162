export interface ContextType {
  isMobile: boolean;
}

export enum RouteType {
  HOMEPAGE = '',
  ABOUT_US = 'about-us',
  DEMO_SCAN = 'dpp-demo-app',
  DEMO_SCAN2 = 'dpp-demo-app2',
  DEMO_VIEW_MAIN = 'dpp-demo-app-main',
  DEMO_VIEW_INFO = 'dpp-demo-app-info',
  DEMO_VIEW_MAP = 'dpp-demo-app-map',
  PRIVACY_POLICY = 'privacy-policy'
}
