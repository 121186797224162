import styled from 'styled-components';

export const StyledContainer = styled.div`
  width: 100%;
  margin: 40px auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledTitle = styled.h1`
  font-size: 1.8rem;
  font-weight: bold;
  margin-top: 40px;
  margin-bottom: 20px;
  text-align: center;
`;

export const StyledImagePlaceholder = styled.div`
  width: 80%;
  height: 300px;
  margin-bottom: 50px;
  background-color: #d3d3d3;
  border-radius: 8px;
`;

export const StyledButton = styled.button`
  width: 60%;
  padding: 15px;
  margin: 15px 5px;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;

  &:hover {
    background-color: #0056b3;
  }
`;

export const StyledButtonBack = styled.button`
  width: 60%;
  padding: 15px;
  margin-top: 60px;
  font-size: 1.1rem;
  font-weight: bold;
  color: #fff;
  background-color: #adadad;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;

  &:hover {
    background-color: #a1a1a1;
  }
`;