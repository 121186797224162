import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { StyledContainer, StyledTitle, StyledImagePlaceholder, StyledButton } from './DemoDPPMainView-styling';

export const DemoDPPMainView: React.FC<{ productId: string }> = ({ productId }) => {
  const navigate = useNavigate();
  const BASE_URL = 'https://cr-api-dev.axelote.com';

  const [productData, setProductData] = useState<any>(null);

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const res = await fetch(`${BASE_URL}/dpp-demo-get-product-data/${productId}`);
        if (!res.ok) throw new Error('Błąd podczas pobierania danych');

        const data = await res.json();
        setProductData(data);
      } catch (error) {
        console.error('Błąd podczas pobierania danych:', error);
      }
    };

    fetchProductData();
  }, []);
  console.log(productData);

  if (!productData) {
    return <div>Ładowanie danych...</div>;
  }

  return (
    <StyledContainer>
      <StyledTitle>{productData.productName || productData.name}</StyledTitle>
      <StyledImagePlaceholder />
      <StyledButton onClick={() => navigate('/dpp-demo-app-info')}>Sprawdź produkt</StyledButton>
      <StyledButton onClick={() => navigate('/dpp-demo-app-map')}>Ślad węglowy</StyledButton>
      <StyledButton>Wróć</StyledButton>
    </StyledContainer>
  );
};
