import React from 'react';
import {StyledCompanyName, StyledFooterWrapper, StyledLink, StyledLinksWrapper, StyledText} from './Footer-styling';
import ContactUsForm from '../contactUsForm/ContactUsForm';

const Footer: React.FC = () => {
    const currentYear = new Date().getFullYear();

    return (
        <StyledFooterWrapper>
            <div>
                <StyledCompanyName>© {currentYear} axelote</StyledCompanyName>
                <StyledText>
                    <b>Skontaktuj się z nami.</b> Chcesz dowiedzieć się więcej o wprowadzeniu Digital Product Passport w
                    Twojej firmie? Chcesz
                    skorzystać z naszego doświadczenia developerskiego? Skontaktuj się z nami, a przygotujemy dedykowaną
                    ofertę dostosowaną do Twoich
                    potrzeb.
                </StyledText>
            </div>
            <ContactUsForm/>
        </StyledFooterWrapper>
    );
};

export default Footer;
