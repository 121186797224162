import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import qrcode from '../../public/assets/new/dppappweb1test.jpg';
import image1 from '../../public/assets/new/banemain1.jpg';
import image2 from '../../public/assets/new/banermain2.jpg';
import image3 from '../../public/assets/new/banermain3.jpg';
import image4 from '../../public/assets/new/image4.webp';
import image5 from '../../public/assets/new/image5.webp';
import image6 from '../../public/assets/new/odkryj_nowe_mozliwosci.jpg';
import imageAxelote from '../../public/assets/new/jak_dziala_axelote.jpg';
import imageBenefits from '../../public/assets/new/korzysci_dla_twojej_firmy_main.jpg';

interface StyledHomepageProps {
  image1: string;
  image2: string;
  image3: string;
  image4: string;
  image5: string;
  image6: string;
  qrcode: string;
  imageAxelote: string;
  imageBenefits: string;
}

export const StyledHomepage = styled.div<StyledHomepageProps>`
  --font-size-h1: 2em;
  --font-size-h2: 1.6em;
  --font-size-p: 1.2em;
  --font-size-li: 1.1em;

  .image-1,
  .image-2,
  .image-3,
  .image-4,
  .image-5,
  .image-6,
  .image-axelote,
  .image-benefits {
    background-repeat: no-repeat;
    background-size: cover;
  }

  .image-axelote {
    background-image: ${({ imageAxelote }) => `url(${imageAxelote})`};
    background-position: center;
    border-radius: 10px;
    min-height: 370px;

    @media (max-width: 768px) {
      min-width: 100%;
    }
  }

  .image-benefits {
    background-image: ${({ imageBenefits }) => `url(${imageBenefits})`};
    width: 30vw;
    height: 50vh;
    position: relative;
    background-position: center;
    border-radius: 10px;
    /* top: 40px; */
    /* right: 20px; */

    @media (max-width: 768px) {
      /* position: relative; */
      width: 100%;
      height: 40vh;
    }
  }

  .image-1 {
    background-image: ${({ image1 }) => `url(${image1})`};
  }

  .image-2 {
    background-image: ${({ image2 }) => `url(${image2})`};
  }

  .image-3 {
    background-image: ${({ image3 }) => `url(${image3})`};
  }

  .image-4 {
    background-image: ${({ image4 }) => `url(${image4})`};
  }

  .image-5 {
    background-image: ${({ image5 }) => `url(${image5})`};
  }

  .image-6 {
    background-image: ${({ image6 }) => `url(${image6})`};
  }

  #dpp-info1 {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    padding: 40px;
    background-color: #f9f9f9; /* Soft background color */
    color: #333;
    box-sizing: border-box;
    flex-wrap: wrap; /* Makes it responsive */
    margin: 40px 90px;
    border-radius: 10px;
    font-size: 1.2rem;
  }

  #dpp-info1 .content {
    flex: 1;
    max-width: 600px;
  }

  #dpp-info1 h1 {
    font-size: var(--font-size-h1);
    color: #0073e6; /* Accent color */
    margin-bottom: 20px;
  }

  #dpp-info1 h2 {
    font-size: var(--font-size-h2);
    margin-top: 30px;
    color: #444;
  }

  #dpp-info1 p {
    font-size: var(--font-size-p);
    line-height: 1.6;
    margin-bottom: 15px;
  }

  #dpp-info1 ul {
    list-style-type: disc;
    padding-left: 20px;
    margin-bottom: 15px;
  }

  #dpp-info1 ul li {
    font-size: var(--font-size-li);
    margin-bottom: 10px;
    line-height: 1.5;
  }

  #dpp-info1 ul li strong {
    /* color: #0073e6; Highlighted color */
  }

  #dpp-info1 .image {
    flex: 1;
    height: 274px;
  }

  /* Responsive Design */
  @media (max-width: 768px) {
    #dpp-info1 {
      flex-direction: column;
      align-items: center;
      margin: 40px 0;
    }

    #dpp-info1 .content {
      max-width: 100%;
      text-align: center;
      font-size: 1.1rem;
    }

    #dpp-info1 ul {
      list-style-type: none; /* Remove bullets on mobile */
      padding-left: 0;
    }
  }

  #dpp-info2 {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    padding: 40px;
    background-color: #f9f9f9; /* Soft background color */
    color: #333;
    box-sizing: border-box;
    flex-wrap: wrap; /* Makes it responsive */
    margin: 40px 90px;
    border-radius: 10px;
  }

  #dpp-info2 .content {
    flex: 1;
    font-size: 1.2rem;
  }

  #dpp-info2 h1 {
    font-size: var(--font-size-h1);
    color: #0073e6; /* Accent color */
    margin-bottom: 20px;
  }

  #dpp-info2 h2 {
    font-size: var(--font-size-h2);
    margin-top: 30px;
    color: #444;
  }

  #dpp-info2 p {
    font-size: var(--font-size-p);
    line-height: 1.6;
    margin-bottom: 15px;
  }

  #dpp-info2 ul {
    list-style-type: disc;
    padding-left: 20px;
    margin-bottom: 15px;
  }

  #dpp-info2 ul li {
    font-size: var(--font-size-li);
    margin-bottom: 20px;
    line-height: 1.5;
  }

  #dpp-info2 ul li:first-of-type {
    margin-top: 30px;
  }

  #dpp-info2 ul li:last-of-type {
    margin-bottom: 40px;
  }

  #dpp-info2 ul li strong {
    /* color: #0073e6; Highlighted color */
  }

  #dpp-info2 .image {
    flex: 1;
    height: 486px;
  }

  /* Responsive Design */
  @media (max-width: 768px) {
    #dpp-info2 {
      margin: 40px 0;
      flex-direction: column;
      align-items: center;
    }

    #dpp-info2 .content {
      max-width: 100%;
      text-align: center;
      font-size: 1.1rem;
    }

    #dpp-info2 ul {
      list-style-type: none; /* Remove bullets on mobile */
      padding-left: 0;
    }

    #dpp-info2 p:last-of-type {
      font-size: 1.1rem;
    }

    #dpp-info2 ul li {
      font-size: 1.1rem;
    }
  }

  #live-demo {
    display: flex;
    max-width: 100%;
    padding: 40px;
    background-color: #f9f9f9;
    color: #333;
    box-sizing: border-box;
    text-align: left;
    margin: 40px 90px;
    border-radius: 10px;
    font-size: 1.2rem;

    .rec1 {
      position: absolute;
      width: 120px;
      height: 10px;
      background: #0049ef;
      top: 0;
      left: 0;
    }

    .rec2 {
      position: absolute;
      width: 10px;
      height: 80px;
      background: #ff0096;
      top: 0;
      left: 0;
      opacity: 0.8;
    }

    .rec3 {
      position: absolute;
      width: 120px;
      height: 10px;
      background: #0049ef;
      bottom: 0;
      right: 0;
    }

    .rec4 {
      position: absolute;
      width: 10px;
      height: 80px;
      background: #ff0096;
      bottom: 0;
      right: 0;
      opacity: 0.8;
    }

    > div:first-child {
      flex: 1;
    }

    > div:last-child {
      display: flex;
      align-items: center;
      justify-content: center;
      /* padding: 0 60px; */
      padding: 0;
      flex: 1;
    }

    img {
      width: 256px;
      height: 256px;
    }

    @media (max-width: 768px) {
      flex-direction: column;
      text-align: center;
      padding: 20px;
      margin: 40px 0;

      > div:last-child {
        padding: 20px 0;
      }

      img {
        width: 200px;
        height: 200px;
      }
    }
  }

  .benefits-container {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    padding-bottom: 50px;
    border-bottom: 1px solid #111;
    min-width: 100%;

    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
    }
  }

  .benefits-info {
    width: 60vw;
    font-size: 1.2rem;

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  #live-demo h1 {
    font-size: var(--font-size-h1);
    color: #0073e6; /* Primary color for the title */
    margin-bottom: 20px;
  }

  #live-demo p {
    font-size: var(--font-size-p);
    line-height: 1.6;
    margin-bottom: 20px;
    max-width: 800px;
  }

  #live-demo h2 {
    font-size: var(--font-size-h2);
    color: #333;
    margin: 30px 0 15px;
  }

  #live-demo ol {
    list-style-type: decimal;
    text-align: left;
    max-width: 800px;
    margin: 0;
    padding: 0 20px;
  }

  #live-demo ol li {
    font-size: var(--font-size-li);
    margin-bottom: 15px;
    line-height: 1.5;
  }

  #live-demo ol li strong {
    /* color: #0073e6; Accent color for highlights */
  }

  #live-demo p:last-of-type {
    font-size: var(--font-size-p);
    font-weight: bold;
    color: #555;
    margin-top: 20px;
  }

  @media (max-width: 768px) {
    #live-demo h1 {
      font-size: 2.2rem;
    }

    #live-demo p:last-of-type {
      font-size: 1.2rem;
    }

    #live-demo ol li {
      font-size: 1.1rem;
    }
  }

  #dpp-app {
    width: 100%;
    padding: 40px;
    background-color: #f3f4f6;
    border-radius: 10px;
    color: #333;
    box-sizing: border-box;
    text-align: left;
    margin: 40px 0;
  }

  #dpp-app h1 {
    font-size: var(--font-size-h1);
    color: #0073e6;
    margin-bottom: 20px;
  }

  #dpp-app p {
    font-size: var(--font-size-p);
    line-height: 1.6;
    margin-bottom: 20px;
    max-width: 800px;
  }

  #dpp-app h2 {
    font-size: var(--font-size-h2);
    color: #333;
    margin: 30px 0 15px;
  }

  #dpp-app ul {
    list-style-type: disc;
    padding-left: 40px; /* Indent list for better readability */
    max-width: 800px;
  }

  #dpp-app ul li {
    font-size: var(--font-size-li);
    margin-bottom: 15px;
    line-height: 1.5;
  }

  #dpp-app ul li strong {
    color: #0073e6;
  }

  #dpp-app p:last-of-type {
    font-size: 1.2em;
    font-weight: bold;
    color: #555;
    margin-top: 20px;
  }

  #benefits {
    max-width: 100%;
    padding: 40px;
    background-color: #f9f9f9;
    border-radius: 10px;
    color: #333;
    box-sizing: border-box;
    text-align: left;
    margin: 40px 90px;
  }

  #benefits h1 {
    font-size: var(--font-size-h1);
    color: #0073e6;
    margin-bottom: 20px;
  }

  #benefits p {
    font-size: var(--font-size-p);
    line-height: 1.6;
    margin-bottom: 20px;
    max-width: 800px;
  }

  #benefits h2 {
    font-size: var(--font-size-h2);
    color: #333;
    margin: 30px 0 15px;
  }

  #benefits ul {
    list-style-type: disc;
    padding-left: 40px; /* Indent list for better readability */
    max-width: 800px;
  }

  #benefits ul li {
    font-size: 1.4rem;
    margin-bottom: 15px;
    line-height: 1.5;
  }

  #benefits ul li strong {
    /* color: #0073e6; */
  }

  #benefits p:last-of-type {
    font-size: var(--font-size-p);
    font-weight: bold;
    color: #555;
    margin-top: 20px;
  }

  @media (max-width: 768px) {
    #benefits {
      margin: 40px 0;
    }

    #benefits h1 {
      text-align: center;
      font-size: 2.2rem;
      margin-bottom: 20px;
    }

    #benefits ul {
      padding-left: 10px;
    }

    #benefits ul li {
      font-size: 1.1rem;
    }
  }

  #producer {
    width: 100%;
    padding: 20px;
    border-radius: 10px;
    color: #333;
    box-sizing: border-box;
    text-align: left;
    margin-top: 60px;
    margin-bottom: 20px;
  }

  #producer h1 {
    font-size: var(--font-size-h1);
    color: #0073e6;
    margin-bottom: 10px;
  }

  #producer p {
    /* font-size: var(--font-size-p); */
    font-size: 1.2rem;
    line-height: 1.6;
    margin-bottom: 20px;
    /* font-family: 'Montserrat', sans-serif; */
    min-width: 100%;
  }

  #producer h2 {
    font-size: var(--font-size-h2);
    color: #333;
    margin: 50px 0 15px;
  }

  #producer ul {
    list-style-type: disc;
    padding-left: 40px;
    min-width: 100%;
  }

  #producer ul li {
    /* font-size: var(--font-size-li); */
    font-size: 1.2rem;
    margin-bottom: 20px;
    line-height: 1.5;
  }

  #producer ul li:first-of-type {
    margin-top: 30px;
  }

  #producer ul li strong {
    /* color: #0073e6; */
  }

  #producer p:last-of-type {
    font-weight: bold;
    color: #555;
    margin-top: 20px;
    text-align: center;
    margin-top: 40px;
    font-size: 1.4rem;
  }

  @media (max-width: 768px) {
    #producer {
      margin-top: 30px;
    }

    #producer h1 {
      font-size: 2rem;
    }

    #producer ul {
      padding-left: 5px;
    }
  }

  #seller {
    width: 100%;
    padding: 20px;
    border-radius: 10px;
    color: #333;
    box-sizing: border-box;
    text-align: left;
    margin: 20px 0;
  }

  #seller h1 {
    font-size: var(--font-size-h1);
    color: #0073e6;
    margin-bottom: 10px;
  }

  #seller p {
    font-size: var(--font-size-p);
    line-height: 1.6;
    margin-bottom: 20px;
    min-width: 100%;
    font-size: 1.2rem;
  }

  #seller h2 {
    font-size: var(--font-size-h2);
    color: #333;
    margin: 50px 0 15px;
  }

  #seller ul {
    list-style-type: disc;
    padding-left: 40px;
    min-width: 100%;
  }

  #seller ul li {
    margin-bottom: 20px;
    line-height: 1.5;
    font-size: 1.2rem;
  }

  #seller ul li:first-of-type {
    margin-top: 30px;
  }

  #seller ul li strong {
    /* color: #0073e6; */
  }

  #seller p:last-of-type {
    font-size: var(--font-size-p);
    font-weight: bold;
    color: #555;
    margin-top: 20px;
    text-align: center;
    margin-top: 40px;
    font-size: 1.4rem;
  }

  @media (max-width: 768px) {
    #seller h1 {
      font-size: 2rem;
    }

    #seller ul {
      padding-left: 5px;
    }
  }

  #everyone {
    width: 100%;
    padding: 20px;
    border-radius: 10px;
    color: #333;
    box-sizing: border-box;
    text-align: left;
    margin: 20px 0;
  }

  #everyone h1 {
    font-size: var(--font-size-h1);
    color: #0073e6;
    margin-bottom: 10px;
  }

  #everyone p {
    font-size: var(--font-size-p);
    line-height: 1.6;
    margin-bottom: 20px;
    min-width: 100%;
    font-size: 1.2rem;
  }

  #everyone h2 {
    color: #333;
    margin: 50px 0 15px;
  }

  #everyone ul {
    list-style-type: disc;
    padding-left: 40px;
    min-width: 100%;
  }

  #everyone ul li {
    margin-bottom: 20px;
    line-height: 1.5;
    font-size: 1.2rem;
  }

  #everyone ul li:first-of-type {
    margin-top: 30px;
  }

  #everyone ul li strong {
    /* color: #0073e6; */
  }

  #everyone p:last-of-type {
    font-size: var(--font-size-p);
    font-weight: bold;
    color: #555;
    /* color: #fa2dbc; */
    margin-top: 20px;
    text-align: center;
    margin-top: 40px;
    font-size: 1.4rem;
  }

  @media (max-width: 768px) {
    #everyone h1 {
      font-size: 2rem;
    }

    #everyone ul {
      padding-left: 5px;
    }
  }

  #legal {
    max-width: 100%;
    padding: 40px;
    background-color: #f3f4f6;
    border-radius: 10px;
    color: #333;
    box-sizing: border-box;
    text-align: left;
    margin: 40px 90px;
  }

  #legal h1 {
    font-size: var(--font-size-h1);
    color: #0073e6;
    margin-bottom: 20px;
  }

  #legal h1.with-margin {
    margin-top: 20px;
  }

  #legal p {
    font-size: var(--font-size-p);
    line-height: 1.6;
    margin-bottom: 20px;
    font-size: 1.2rem;
  }

  #legal h2 {
    font-size: var(--font-size-h2);
    color: #333;
    margin: 30px 0 15px;
  }

  #legal ul {
    list-style-type: disc;
    padding-left: 40px; /* Indent list for better readability */
    max-width: 800px;
  }

  #legal ul li {
    font-size: var(--font-size-li);
    margin-bottom: 15px;
    line-height: 1.5;
    font-size: 1.2rem;
  }

  #legal ul li strong {
    /* color: #0073e6; */
  }

  #legal p:last-of-type {
    font-size: var(--font-size-p);
    font-weight: bold;
    color: #555;
    margin-top: 20px;
  }

  @media (max-width: 768px) {
    #legal {
      margin: 40px 0;
    }

    #legal ul {
      padding-left: 5px;
    }
  }

  .numberList {
    list-style: none;
  }

  .centeredText {
    text-align: center;
  }

  .preview {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  .preview-text {
    text-align: left;
    margin-top: 20px;
    font-size: 1.4rem;

    @media (max-width: 768px) {
      font-size: 1.1rem;
      line-height: 1.4;
    }
  }

  .legal-title-container {
    display: flex;
    justify-content: center;
    align-items: center;

    h1 {
      margin-top: 10px;
    }
  }

  @media (max-width: 768px) {
    .legal-title-container {
      flex-direction: column;
    }
  }
`;

export const StyledBanner = styled.div`
  background: linear-gradient(135deg, #005eff, #00d4ff);
  padding: 40px;
  border-radius: 10px;
  text-align: left;
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 40px 90px;

  @media (max-width: 768px) {
    margin: 40px 0;
    padding: 50px 10px;
    flex-direction: column;
    text-align: center;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 60%;

  @media (max-width: 768px) {
    min-width: 80%;
  }
`;

export const StyledTitle = styled.h1`
  font-size: 2.3rem;
  margin-bottom: 22px;
  color: #fff;
`;

export const StyledImage = styled.div<{ image: string }>`
  width: 50%;
  height: 400px;
  background-image: url(${(props) => props.image});
  background-size: cover;
  border-radius: 10px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const StyledToggleArrow = styled.span`
  cursor: pointer;
  font-size: 1.6rem;
  padding: 10px;
  margin-left: 10px;
  color: #333;
  &:hover {
    color: #555;
  }
`;

const fadeInOut = keyframes`
  0%, 100% {
    opacity: 0;
  }
  10%, 90% {
    opacity: 1;
  }
`;

export const StyledParagraphContainer = styled.div`
  position: relative;
  width: 100%;
  height: 22rem;
  overflow: hidden;
  margin-bottom: 80px;

  @media (max-width: 768px) {
    height: 26rem;
    margin-bottom: 20px;
  }
`;

export const StyledParagraph = styled.p`
  font-size: 1.4rem;
  color: #e0e0e0;
  line-height: 1.8;
  margin-top: 40px;
  position: absolute;
  width: 100%;
  animation: ${fadeInOut} 12s infinite;

  @media (max-width: 768px) {
    font-size: 1.1rem;
  }
`;

export const StyledButton = styled.button`
  padding: 15px 30px;
  margin-bottom: 20px;
  font-size: 1.2rem;
  color: white;
  background-color: #0041c2;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #002d8a;
  }

  @media (max-width: 768px) {
    align-self: center;
  }
`;

const slides = [
  {
    image: image1,
    text: 'Włącz się w łańcuch przejrzystości, odpowiedzialności i troski. Dzięki Cyfrowemu Paszportowi Produktu (DPP) Twoja marka staje się częścią zrównoważonej przyszłości. Zapewnij klientom dostęp do pełnej historii produktów, od surowca po recykling – twórz przyszłość świadomie i buduj zaufanie na lata.',
  },
  {
    image: image2,
    text: 'Szukaj produktów oznaczonych Qrkod i logo DPP, aby mieć pewność, że są one bezpieczne, ekologiczne i pochodzą od odpowiedzialnych producentów. Dzięki DPP masz pełny wgląd w cykl życia produktu, od surowców po recykling, co pomaga Ci podejmować odpowiedzialne decyzje zakupowe.',
  },
  {
    image: image3,
    text: 'Cyfrowy Paszport Produktu to Twoje narzędzie do budowania zaufania i zwiększania wartości oferty. Dzięki pełnej transparentności możesz zapewnić klientom pewność, że sprzedawane przez Ciebie produkty są autentyczne, bezpieczne i mają minimalny wpływ na środowisko. To prosty sposób na wyróżnienie się na rynku i wspieranie świadomych, odpowiedzialnych wyborów zakupowych klientów.',
  },
];

export const Homepage: React.FC<{ footerRef: any }> = ({ footerRef }) => {
  const [currentParagraphIndex, setCurrentParagraphIndex] = useState(0);

  const [isLegalSectionVisible, setIsLegalSectionVisible] = useState(false);

  const [isProducerVisible, setIsProducerVisible] = useState(false);
  const [isSellerVisible, setIsSellerVisible] = useState(false);
  const [isEveryoneVisible, setIsEveryoneVisible] = useState(false);

  const scrollToFooter = () => {
    footerRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const toggleLegalSectionVisibility = () => {
    setIsLegalSectionVisible((prev) => !prev);
  };

  const toggleProducerVisibility = () => {
    setIsProducerVisible((prev) => !prev);
  };

  const toggleSellerVisibility = () => {
    setIsSellerVisible((prev) => !prev);
  };

  const toggleEveryoneVisibility = () => {
    setIsEveryoneVisible((prev) => !prev);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentParagraphIndex((prevIndex) => (prevIndex + 1) % slides.length);
    }, 12000);

    return () => clearInterval(interval);
  }, []);

  return (
    <StyledHomepage
      image1={image1}
      image2={image2}
      image3={image3}
      image4={image4}
      image5={image5}
      image6={image6}
      qrcode={qrcode}
      imageAxelote={imageAxelote}
      imageBenefits={imageBenefits}>
      <StyledBanner>
        <ContentContainer>
          <StyledTitle>Twórz z nami swoją przyszłość</StyledTitle>
          <StyledParagraphContainer>
            <StyledParagraph key={currentParagraphIndex}>{slides[currentParagraphIndex].text}</StyledParagraph>
          </StyledParagraphContainer>
          <StyledButton onClick={scrollToFooter}>Skontaktuj się</StyledButton>
        </ContentContainer>
        <StyledImage image={slides[currentParagraphIndex].image} />
      </StyledBanner>

      <section id="dpp-info1">
        <div className="content">
          <h1>Jak działa Axelote DPP?</h1>
          <ul>
            <li>
              <strong>Informacje dla klientów:</strong> Udostępnienie klientom wszystkich niezbędnych danych za pomocą jednego kliknięcia,
              dzięki kodom QR.
            </li>
            <li>
              <strong>Integracje z systemem GS1:</strong> Jeden kod, wykorzystany w wielu miejscach, żeby skrócić proces wprowadzania
              produktów - jedno miejsce - wiele rozwiązań.
            </li>
            <li>
              <strong>Śledzenie surowców:</strong> Pełna kontrola nad pochodzeniem materiałów, z których powstają Twoje produkty.
            </li>
          </ul>
        </div>
        <div className="image image-axelote"></div>
      </section>

      <section id="live-demo">
        <div className="rec1"></div>
        <div className="rec2"></div>
        <div className="rec3"></div>
        <div className="rec4"></div>
        <div>
          <h1>Przetestuj Live Demo!</h1>
          <p>
            Poznaj pełną przejrzystość, pewność autentyczności i nowe możliwości zarządzania produktami. Zobacz, jak nasza aplikacja może
            zrewolucjonizować Twoją sprzedaż i budować zaufanie klientów.
          </p>
          <ol>
            <li>
              <strong>Bezpieczne produkty:</strong> Cyfrowy Paszport Produktu zapewnia, że każdy produkt jest bezpieczny do użytku, wolny od
              niebezpiecznych substancji i wykonany zgodnie z najnowszymi normami bezpieczeństwa.
            </li>
            <li>
              <strong>Ekologiczne wybory:</strong> DPP daje Ci narzędzia, które pozwalają wybierać produkty minimalizujące wpływ na
              środowisko, dzięki czemu dbasz o naszą planetę.
            </li>
            <li>
              <strong>Pewność oryginalności:</strong> Dzięki DPP masz gwarancję, że kupujesz produkt autoryzowanego producenta, a nie
              podróbkę, co daje pewność co do jakości i bezpieczeństwa - niezależnie od tego, czy wolisz kupować nowe czy używane produkty.
            </li>
          </ol>
        </div>
        <div>
          <img src={qrcode} />
        </div>
      </section>

      <section id="dpp-info2">
        <div className="content">
          <div>
            <h1>Odkryj nowe możliwości</h1>
            <p>
              Cyfrowy Paszport Produktu (DPP) to Twoje wszechstronne narzędzie do pokazania pełnej transparentności i zarządzania
              produktami. Nasza aplikacja pozwala wprowadzać dane dowolnych produktów na dowolnym etapie produkcji, generować kod QR,
              skanować i odczytywać dane z DPP, co otwiera nowe możliwości zarówno dla producentów, sprzedawców indywidualnych, jak i handlu
              vintage.
            </p>
            <div className="image image-6"></div>
          </div>
          <div>
            <h2 style={{ marginBottom: '20px' }}>Oto co zyskasz:</h2>
            <ul>
              <li>
                <strong>Tworzenie Indywidualnych Cyfrowych Paszportów Produktu:</strong> Zapewnij każdy produkt pełnym cyfrowym paszportem.
              </li>
              <li>
                <strong>Zapewnienie przejrzystości:</strong> Implementacja technologii kodów QR umożliwia łatwy dostęp do DPP dla klientów.
              </li>
              <li>
                <strong>Pewność co do autentyczności:</strong> Zweryfikowane informacje o pochodzeniu i historii produktu zwiększają jego
                wartość i zaufanie klientów.
              </li>
              <li>
                <strong>Integracja z platformami sprzedażowymi:</strong> Bezproblemowa synchronizacja, dzięki której z łatwością zarządzasz
                ofertą i prezentujesz pełne informacje o produktach.
              </li>
              <li>
                <strong>Dane po sprzedaży:</strong> Śledzenie cyklu życia produktu i preferencji klientów pozwala na doskonalenie oferty i
                budowanie długoterminowych relacji.
              </li>
              <li>
                <strong>Przejrzystość i zrównoważony rozwój:</strong> Zyskaj przewagę na rynku, stawiając na transparentność i świadome
                zakupy.
              </li>
            </ul>
            <p className="centeredText">
              <strong style={{ color: '#0073e6' }}>
                Aplikacja DPP – Twój klucz do innowacyjnego zarządzania produktami i budowania trwałego zaufania.
              </strong>
            </p>
          </div>
        </div>
      </section>

      <section id="benefits">
        <h1>Korzyści dla Twojej firmy</h1>
        <div className="benefits-container">
          <div className="benefits-info">
            <ul>
              <li>
                <strong>Optymalizacja procesów:</strong> Monitoruj każdy etap cyklu życia produktu i zarządzaj nim efektywnie.
              </li>
              <li>
                <strong>Budowanie zaufania klientów:</strong> Wspieraj zrównoważony rozwój, pokazując odpowiedzialność za środowisko i
                etyczne źródła surowców.
              </li>
              <li>
                <strong>Zwiększenie transparentności:</strong> Twoi klienci zyskają pełny wgląd w pochodzenie i skład produktów.
              </li>
              <li>
                <strong>Zgodność z regulacjami UE:</strong> Zabezpiecz swoją firmę, spełniając nadchodzące regulacje związane z Europejskim
                Zielonym Ładem.
              </li>
              <li>
                <strong>Świadome podejmowanie decyzji:</strong> Dzięki szczegółowym danym z DPP Twoja firma może wprowadzać ulepszenia,
                które ograniczą wpływ na środowisko.
              </li>
              <li>
                <strong>Ocena śladu węglowego:</strong> Automatyczne wyliczanie emisji CO2 na każdym etapie produkcji i transportu.
              </li>
              <li>
                <strong>Certyfikaty i standardy:</strong> Integracja z najważniejszymi certyfikatami ekologicznymi, takimi jak ISO 14001,
                GOTS, czy Fair Trade.
              </li>
            </ul>
          </div>
          <div className="image image-benefits"></div>
        </div>
        <div id="producer">
          <h1>Dla producenta</h1>
          <div className="preview">
            <span className="preview-text">
              <strong>Zainwestuj w przyszłość</strong> swojej marki dzięki Cyfrowemu Paszportowi Produktu (DPP). Nasza innowacyjna aplikacja
              umożliwia producentom nie tylko prezentowanie pełnych informacji o swoich produktach, ale także zbieranie kluczowych danych po
              ich sprzedaży. To nowe podejście, które zmienia relacje z klientami i otwiera drzwi do nowych możliwości rozwoju.
            </span>
            <StyledToggleArrow onClick={toggleProducerVisibility}>{isProducerVisible ? '↑' : '↓'}</StyledToggleArrow>
          </div>
          {isProducerVisible && (
            <>
              <h2>Co zyskasz dzięki naszej aplikacji?</h2>
              <ul>
                <li>
                  <strong>Wgląd w użycie i preferencje klientów:</strong> Dowiedz się, jak Twoje produkty są użytkowane po ich zakupie.
                </li>
                <li>
                  <strong>Lepsza strategia rozwoju produktów:</strong> Wykorzystaj dane zbierane po sprzedaży, aby ulepszać swoje produkty.
                </li>
                <li>
                  <strong>Podtrzymywanie kontaktu z klientami:</strong> Stwórz platformę do budowania długoterminowej relacji z klientami.
                </li>
                <li>
                  <strong>Monitorowanie cyklu życia produktu:</strong> Poznaj szczegóły dotyczące tego, co dzieje się z Twoim produktem po
                  jego sprzedaży.
                </li>
              </ul>
              <h2>Dlaczego warto wdrożyć DPP?</h2>
              <ul>
                <li>
                  <strong>Cenna wiedza o klientach:</strong> Zyskaj unikalne dane, które pomogą lepiej zrozumieć preferencje użytkowników.
                </li>
                <li>
                  <strong>Nowe kanały komunikacji:</strong> Dzięki aplikacji możesz lepiej docierać do konsumentów i zwiększać ich
                  zaangażowanie.
                </li>
                <li>
                  <strong>Zrównoważony rozwój:</strong> Pokaż klientom, że zależy Ci na pełnym cyklu życia produktu i jego wpływie na
                  środowisko.
                </li>
              </ul>
              <p>
                <strong>Zbuduj przewagę konkurencyjną:</strong> Inwestując w DPP, pokazujesz, że jesteś liderem innowacji i przejrzystości.
              </p>
            </>
          )}
        </div>
        <div id="seller">
          <h1>Dla sprzedawcy</h1>
          <div className="preview">
            <span className="preview-text">
              Nasza aplikacja oparta na Cyfrowym Paszporcie Produktu (DPP) <strong>otwiera nowe perspektywy</strong> i gwarantuje pewność co
              do autentyczności Twojego asortymentu. Dzięki integracji z platformami sprzedażowymi i unikalnym funkcjom DPP, łatwo
              zwiększysz zaufanie klientów oraz zoptymalizujesz procesy sprzedażowe.
            </span>
            <StyledToggleArrow onClick={toggleSellerVisibility}>{isSellerVisible ? '↑' : '↓'}</StyledToggleArrow>
          </div>
          {isSellerVisible && (
            <div>
              <h2>Co zyskasz z DPP?</h2>
              <ul>
                <li>
                  <strong>Potwierdzenie oryginalności:</strong> Każdy produkt opatrzony DPP to gwarancja jego autentyczności, co zwiększa
                  jego wartość w oczach klientów.
                </li>
                <li>
                  <strong>Historia i szczegółowe informacje:</strong> Zapewnij dostęp do szczegółowych danych o pochodzeniu, składzie i
                  historii produktów.
                </li>
                <li>
                  <strong>Integracja z platformami sprzedażowymi:</strong> Bezproblemowo połącz swoje DPP z popularnymi platformami
                  sprzedażowymi.
                </li>
                <li>
                  <strong>Śledzenie i analiza po sprzedaży:</strong> Uzyskaj dostęp do danych o użytkowaniu produktów po ich sprzedaży.
                </li>
              </ul>
              <h2>Dlaczego warto wdrożyć DPP?</h2>
              <ul>
                <li>
                  <strong>Budowanie zaufania klientów:</strong> Klienci cenią sobie pewność, że produkt jest oryginalny i dokładnie opisany.
                </li>
                <li>
                  <strong>Nowe możliwości rozwoju:</strong> Świadomość, co dzieje się z produktami po sprzedaży, pozwala doskonalić ofertę.
                </li>
                <li>
                  <strong>Zwiększona wartość produktów:</strong> Dzięki DPP i dostępowi do pełnych informacji o produktach Twoje oferty
                  zyskują na atrakcyjności.
                </li>
              </ul>
              <h2>Jak działa DPP?</h2>
              <ul>
                <li className="numberList">
                  <strong>1. Wyszukaj produkt</strong> i dodaj do swojego konta DPP.
                </li>
                <li className="numberList">
                  <strong>2. Zintegruj się</strong> z platformami sprzedażowymi.
                </li>
                <li className="numberList">
                  <strong>3. Uzyskaj dane</strong> zwrotne o preferencjach klientów.
                </li>
              </ul>
              <p>
                <strong>Wprowadź swoje produkty na nowy poziom!</strong>
              </p>
            </div>
          )}
        </div>
        <div id="everyone">
          <h1>Dla klienta końcowego</h1>
          <div className="preview">
            <span className="preview-text">
              Z naszą innowacyjną aplikacją do odczytywania danych z Cyfrowego Paszportu Produktu (DPP), <strong>zyskujesz dostęp</strong>{' '}
              do kluczowych informacji o każdym produkcie za pomocą jednego skanu kodu QR. Odkryj świat przejrzystości, bezpieczeństwa i
              świadomych wyborów zakupowych.
            </span>
            <StyledToggleArrow onClick={toggleEveryoneVisibility}>{isEveryoneVisible ? '↑' : '↓'}</StyledToggleArrow>
          </div>
          {isEveryoneVisible && (
            <div>
              <h2>Co oferuje nasza aplikacja?</h2>
              <ul>
                <li>
                  <strong>Skanowanie i odczyt kodów QR:</strong> Błyskawicznie zeskanuj kod QR lub wprowadź numery kodu paskowego.
                </li>
                <li>
                  <strong>Cyfrowy katalog produktów:</strong> Twórz i przechowuj własny katalog ulubionych produktów.
                </li>
                <li>
                  <strong>Śledzenie cyklu życia produktu:</strong> Dowiedz się, jak Twój produkt wpływa na środowisko.
                </li>
                <li>
                  <strong>Bezpieczeństwo i zaufanie:</strong> Dzięki przechowywaniu zweryfikowanych danych masz pewność, że dokonujesz
                  bezpiecznych wyborów.
                </li>
              </ul>
              <h2>Dlaczego warto?</h2>
              <ul>
                <li>
                  <strong>Odpowiedzialne zakupy:</strong> Poznaj szczegóły dotyczące pochodzenia i składu produktów.
                </li>
                <li>
                  <strong>Przejrzystość na wyciągnięcie ręki:</strong> Zyskaj pełen obraz cyklu życia produktów.
                </li>
                <li>
                  <strong>Twoje zaufane źródło informacji:</strong> Skorzystaj z technologii, która sprawia, że każdy produkt jest jak
                  otwarta księga.
                </li>
              </ul>
              <h2>Jak to działa?</h2>
              <ul>
                <li className="numberList">
                  <strong>1. Skanuj kod QR</strong> z dowolnego produktu.
                </li>
                <li className="numberList">
                  <strong>2. Odczytaj informacje</strong> o produkcie, w tym pochodzenie, ślad węglowy, skład i certyfikaty.
                </li>
                <li className="numberList">
                  <strong>3. Zapisz ulubione</strong> produkty w cyfrowym katalogu.
                </li>
                <li className="numberList">
                  <strong>4. Odsprzedaj</strong> już nieużywane produkty dzięki integracji z serwisami sprzedażowymi.
                </li>
              </ul>
              <p>
                <strong>Z aplikacją DPP każdy skan to krok ku lepszemu, bardziej świadomemu i zrównoważonemu życiu.</strong>
              </p>
            </div>
          )}
        </div>
      </section>

      <section id="legal">
        <div className="legal-title-container">
          <h1>Wybrane akty prawne dotyczące opracowania i wdrażania Cyfrowego Paszportu Produktu (DPP)</h1>
          <StyledToggleArrow onClick={toggleLegalSectionVisibility}>{isLegalSectionVisible ? '↑' : '↓'}</StyledToggleArrow>
        </div>

        {isLegalSectionVisible && (
          <>
            <h2>1. Propozycja Rozporządzenia w sprawie Zrównoważonych Produktów (Sustainable Products Initiative, SPI)</h2>
            <ul>
              <li>
                <strong>Data publikacji:</strong> 30 marca 2022 roku.
              </li>
              <li>
                <strong>Cel:</strong> Ustanowienie zasad projektowania zrównoważonych produktów i wprowadzenie obowiązku stosowania DPP w
                celu
              </li>
            </ul>

            <h2>2. Europejski Zielony Ład (European Green Deal)</h2>
            <ul>
              <li>
                <strong>Data przyjęcia:</strong> 11 grudnia 2019 roku.
              </li>
              <li>
                <strong>Cel:</strong> Główny plan działania UE w kierunku zrównoważonego rozwoju, obejmujący wprowadzenie środków
                wspierających gospodarkę o obiegu zamkniętym i transparentność produktów, w tym DPP.
              </li>
            </ul>

            <h2>3. Plan działania na rzecz gospodarki o obiegu zamkniętym (Circular Economy Action Plan)</h2>
            <ul>
              <li>
                <strong>Data przyjęcia:</strong> 11 marca 2020 roku.
              </li>
              <li>
                <strong>Cel:</strong> Wspiera wdrażanie DPP jako narzędzia do zwiększenia transparentności i śledzenia cyklu życia
                produktów, co pozwala na promowanie bardziej zrównoważonych wyborów konsumenckich.
              </li>
            </ul>

            <h1 className="with-margin">Akty prawne dotyczące bezpieczeństwa pochodzenia produktów</h1>

            <h2>
              1. Rozporządzenie (UE) nr 995/2010 w sprawie ustanowienia obowiązków podmiotów wprowadzających drewno i produkty z drewna na
              rynek (EU Timber Regulation)
            </h2>
            <ul>
              <li>
                <strong>Cel:</strong> Zapobieganie wprowadzaniu na rynek UE nielegalnie pozyskanego drewna i zapewnienie śledzenia
                pochodzenia materiału.
              </li>
            </ul>

            <h2>2. Rozporządzenie Parlamentu Europejskiego i Rady (UE) 2017/821</h2>
            <ul>
              <li>
                <strong>Cel:</strong> Zwiększenie przejrzystości i odpowiedzialności w łańcuchu dostaw, zapewnienie bezpieczeństwa
                pochodzenia i zgodności produktów z regulacjami.
              </li>
            </ul>

            <h2>3. Dyrektywa 2011/83/UE o prawach konsumentów</h2>
            <ul>
              <li>
                <strong>Cel:</strong> Wprowadza przepisy dotyczące przejrzystości informacji udzielanych konsumentom, w tym o pochodzeniu
                produktów, co pośrednio wpływa na bezpieczeństwo i śledzenie pochodzenia towarów.
              </li>
            </ul>
          </>
        )}
      </section>
    </StyledHomepage>
  );
};
