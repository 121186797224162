import styled from 'styled-components';

export const formInput = `
  border: none;
  border-radius: 4px;
  padding: 16px 10px;
  outline: none;
      width: 100%;
`;

export const StyledContainer = styled.div`
  background: transparent;
  padding: 0;
  z-index: 99;
  min-width: 600px;

  & .send-success {
    font-size: 14px;
    color: white;
    display: flex;
    align-items: flex-start;
    height: 140px;
    padding: 16px 2px;
  }

  & form {
    width: 100%;
    color: #fff;
    padding-top: 20px;

    & input {
      ${formInput}
    }

    & textarea {
      ${formInput};
      resize: none;
      height: 90px;
    }

    & > *:first-child,
    & > *:last-child {
      grid-column: 1 / span 3;
    }

    & > *:nth-child(3),
    & > *:nth-child(5) {
      border-radius: 0 4px 4px 0;
    }

    & > *:nth-child(2),
    & > *:nth-child(4) {
      border-radius: 4px 0 0 4px;
      grid-column: 1 / span 2;
    }

    @media (max-width: 1100px) {
      & > *:nth-child(2) {
        grid-column: 1 / span 3;
      }
      & > *:nth-child(4) {
        grid-column: 1 / span 3;
        grid-row: 3;
      }
      & > *:nth-child(3),
      & > *:nth-child(5) {
        grid-row: 4;
      }
      & > *:nth-child(3) {
        grid-column: 1 / span 2;
      }
    }
  }

  & button {
    width: 100%;

    &:hover {
      background-color: white;
    }

    @media (max-width: 768px) {
      width: 80vw;
      margin-bottom: 40px;
    }
  }

  & .row {
    float: right;
  }

  @media (max-width: 1100px) {
    max-height: fit-content;
  }
`;
