import React from 'react';
import { DemoDPPProductPage } from '../components/molecules/demoDPPProductPage/DemoDPPProductPage';
import styled from 'styled-components';

export const StyledWrapper = styled.div`
  height: 100%;
  width: 100%;
  margin: 20px 0px;
`;

export const StyledPhoneScreen = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: 1.1rem;
  padding: 5px;
  box-sizing: border-box;
`;

export const DemoScan: React.FC = () => {
  return (
    <StyledWrapper>
      <StyledPhoneScreen>
        <DemoDPPProductPage />
      </StyledPhoneScreen>
    </StyledWrapper>
  );
};
